import React from 'react';
import { Table } from 'antd';

const TableComponent = ({
  columns,
  data,
  rowSelection,
  loading,
}) => {  
  return (
    <Table
      {...(rowSelection && { rowSelection })}
      {...(loading && { loading })}
      columns={columns}
      dataSource={data}
      size='small'
    />
  )
};
export default TableComponent;