import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function createSpreadsheetMutation({ input }) {
  const response = await axios.post(
    `${API}/accredit-network/create-spreadsheet`,
    input,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const createSpreadsheet = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => createSpreadsheetMutation(e),
  );

  return {
    isSuccessCreateSpreadsheet: isSuccess,
    dataCreateSpreadsheet: data,
    isLoadingCreateSpreadsheet: isLoading,
    errorCreateSpreadsheet: error,
    fetchCreateSpreadsheet: mutate,
  }
}
