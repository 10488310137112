import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function getPlans(params) {
  const response = await axios.get(
    `${API}/products/plans`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
      params: params,
    },
  );
  return response.data;
}

export const plansQuery = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getPlans(e),
  );

  return {
    isSuccessPlans: isSuccess,
    dataPlans: data,
    isLoadingPlans: isLoading,
    errorPlans: error,
    refetchPlans: mutate,
  }
}