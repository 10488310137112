import { useEffect, useState } from "react";
import { unionBy } from "lodash";
import { notification } from "antd";
import { plansQuery } from "../use-cases/queries/plansQuery";
import { productsQuery } from "../../Products/useCases/queries/productsQuery";
import getColumnSearchProps from "../../../componentes/molecules/getColumnSearchProps";
import { createSpreadsheet } from "../use-cases/mutation/create-spreadsheet";
import { captalize } from "../../../utils/utils";
import { StatesMap } from "../../../utils/constants";

export const comparativeModel = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };
  const [productsGroup, setProductsGroup] = useState([]);
  const [plansSelected, setPlansSelected] = useState([]);
  const [queryPlanFilters, setQueryPlanFilters] = useState({});
  const rowSelection = {
    selectedRowKeys: plansSelected?.map((plan) => plan.key),
    onChange: () => {},
    onSelect: (record, selected, selectedRows) => {
      selected
        ? setPlansSelected([...plansSelected, record])
        : setPlansSelected(
            plansSelected?.filter((plan) => plan.key !== record.key),
          );
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      selected
        ? setPlansSelected([...plansSelected, ...changeRows])
        : setPlansSelected(
            plansSelected?.filter(
              (plan) =>
                !changeRows.some((changeRow) => changeRow.key === plan.key),
            ),
          );
    },
  };

  const {
    isSuccessCreateSpreadsheet,
    dataCreateSpreadsheet,
    isLoadingCreateSpreadsheet,
    errorCreateSpreadsheet,
    fetchCreateSpreadsheet,
  } = createSpreadsheet();

  useEffect(() => {
    if (isSuccessCreateSpreadsheet && !isLoadingCreateSpreadsheet) {
      openNotificationWithIcon(
        "success",
        "Sucesso",
        dataCreateSpreadsheet.message,
      );
      window.open(dataCreateSpreadsheet?.url, "_blank");
    }

    if (errorCreateSpreadsheet && !isLoadingCreateSpreadsheet) {
      openNotificationWithIcon(
        "error",
        "Erro",
        "Erro ao gerar a planilha, tente novamente mais tarde.",
      );
    }
  }, [
    isSuccessCreateSpreadsheet,
    dataCreateSpreadsheet,
    isLoadingCreateSpreadsheet,
    errorCreateSpreadsheet,
  ]);

  const onClickCreateSpreadsheet = () => {
    if (!plansSelected.length) {
      openNotificationWithIcon(
        "warning",
        "Aviso",
        "Selecione ao menos um plano para gerar a planilha",
      );
      return;
    }

    const input = {
      plans: plansSelected.map((plan) => ({
        name: plan.name,
        provider: plan.provider,
        productGroupId: plan.productGroupId,
      })),
      ...( queryPlanFilters?.city && {
        cities: [queryPlanFilters?.city],
      }),
    };

    fetchCreateSpreadsheet({ input });
  };

  const { dataPlans, isLoadingPlans, refetchPlans } =
    plansQuery(queryPlanFilters);
 
  useEffect(() => {
    refetchPlans(queryPlanFilters)
  }, [])

  const onFilterPlans = () => {
    refetchPlans(queryPlanFilters);
  }

  const { dataProducts, isLoadingProducts, isSuccessProducts } = productsQuery({});

  useEffect(() => {
    if (!isLoadingProducts && isSuccessProducts) {
      setProductsGroup(unionBy(dataProducts, "productGroupId"));
    }
  }, [isLoadingProducts]);

  const dataTable = dataPlans?.map((data, key) => {
    const product = productsGroup?.find(
      (product) => data.productGroupId === product.productGroupId,
    );

    return {
      id: data.id,
      name: data.name,
      productGroupId: data.productGroupId,
      product,
      key,
      state: data?.state || "",
      provider: data?.provider || "",
    };
  });

  const plansColumns = [
    {
      title: "Plano",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "Plano"),
    },
    {
      title: "Provedor",
      dataIndex: "provider",
      key: "provider",
      ...getColumnSearchProps("provider", "Provedor"),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",

      filters: StatesMap.map((state) => ({
        text: `${state.name} - ${state.sigla}`,
        value: state.name,
      })).sort((a, b) => {
        if (a.text > b.text) {
          return 1;
        }
        if (a.text < b.text) {
          return -1;
        }
        return 0;
      }),
      onFilter: (value, record) => record.state === value,
      render: (value) => <text>{captalize(value)}</text>,
      // ...getColumnSearchProps('state', "Estado"),
    },
  ];

  const plansSelectedColumns = [
    ...plansColumns,

    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <span>
          <a
            onClick={() => {
              setPlansSelected(
                plansSelected?.filter((plan) => plan.key !== record.key),
              );
            }}
          >
            Remover
          </a>
        </span>
      ),
    },
  ];

  return {
    contextHolder,
    isLoadingPlans,
    dataPlans: dataTable,
    plansColumns,
    rowSelection,
    plansSelected,
    plansSelectedColumns,
    onClickCreateSpreadsheet,
    queryPlanFilters,
    setQueryPlanFilters,
    onFilterPlans,
  };
};
