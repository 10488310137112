import { Button, Card, Collapse, Input, Typography } from "antd";
import TableComponent from "../../componentes/molecules/TableComponent";
import { comparativeModel } from "./view-model/comparativeModel";
import { SearchOutlined } from "@ant-design/icons";

const ComparativeRoute = () => {
  const { Title } = Typography;

  const {
    contextHolder,
    isLoadingPlans,
    dataPlans,
    plansColumns,
    rowSelection,
    plansSelected,
    plansSelectedColumns,
    onClickCreateSpreadsheet,
    queryPlanFilters,
    setQueryPlanFilters,
    onFilterPlans,
  } = comparativeModel();

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "30px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "30px" }}>
          <Title level={2}>Comparativo de Planos</Title>
        </div>

        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Collapse.Panel
            header={<Title level={4}>Planos disponíveis</Title>}
            key="1"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 12,
              }}
            >
              <label>Buscar por cidade</label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  placeholder="Cidade"
                  value={queryPlanFilters.city}
                  onChange={(e) =>
                    setQueryPlanFilters({
                      ...queryPlanFilters,
                      city: e.target.value,
                    })
                  }
                  style={{ alignSelf: "baseline", width: "30%" }}
                  required
                  // ref="ewqw"
                />

                <Button
                  type="primary"
                  onClick={() => {
                    console.log("onClick");
                    onFilterPlans()}}
                  icon={<SearchOutlined />}
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <TableComponent
              data={dataPlans}
              columns={plansColumns}
              rowSelection={rowSelection}
              loading={isLoadingPlans}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div
                style={{
                  display: "flex",
                  gap: 12,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title style level={4}>
                  Planos selecionados
                </Title>
                <Button type="default" onClick={onClickCreateSpreadsheet}>
                  Gerar planilha
                </Button>
              </div>
            }
            key="2"
            style={{}}
          >
            <TableComponent
              data={plansSelected}
              columns={plansSelectedColumns}
              loading={isLoadingPlans}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

export default ComparativeRoute;
