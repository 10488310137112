import React, { useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  ExperimentOutlined,
  RightOutlined,
  PieChartOutlined,
  LeftOutlined,
  FileDoneOutlined,
  UserOutlined,
  ShopOutlined,
  GoldOutlined,
  SnippetsOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import Flex from '../atoms/Flex';
import { removeAccessToken } from '../../utils/localStorage';

const SideMenu = () => {

  const navigate = useNavigate()

  const handleOnClick = useCallback((nomeRota) => {
    navigate(`/${nomeRota}`, {replace: true})
  }, [navigate]);

  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Flex style={{width: '100%'}}>
      <Flex>
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={[
            {
              key: '1',
              icon: collapsed ? <RightOutlined /> : <LeftOutlined />,
              label: "",
              onClick: () => toggleCollapsed(),
            },
            {
              key: '2',
              icon: <ShopOutlined />,
              label: "Empresas",
              onClick: () => handleOnClick('companies'),
            },
            {
              key: '3',
              icon: <UserOutlined />,
              label: "Individuos",
              onClick: () => handleOnClick('individuals'),
            },
            {
              key: '4',
              icon: <FileDoneOutlined />,
              label: "Contratos",
              onClick: () => handleOnClick('contracts'),
            },
            {
              key: '5',
              icon: <GoldOutlined />,
              label: "Produtos",
              onClick: () => handleOnClick('products'),
            },
            {
              key: '6',
              icon: <ExperimentOutlined />,
              label: "Rede Credenciada",
              onClick: () => handleOnClick('accredit-network'),
            },
            {
              key: '7',
              icon: <SnippetsOutlined />,
              label: "Voucher",
              onClick: () => handleOnClick('voucher'),
            },
            {
              key: '8',
              icon: <SwapOutlined />,
              label: "Comparativo",
              onClick: () => handleOnClick('comparative'),
            },
            {
              key: '0',
              icon: <AppstoreOutlined />,
              label: "Sair",
              onClick: () => {
                removeAccessToken()
                handleOnClick('login')
              },
            },
          ]}
        />
      </Flex>
      <Outlet/>
    </Flex>
  )
};
export default SideMenu;