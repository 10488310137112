const AccommodationMap = {
  shared:  'Compartilhado',
  private: 'Individual',
}

const AdherenceMap = {
  compulsory:  'Compulsório',
  loose: 'Livre Adesão',
}

const CopaymentMap = {
  partial:  'Parcial',
  without: 'Sem',
  with: 'Com',
  with_partial: "Parcial",
}

const StatesMap = [
  {
    name: "Acre",
    sigla: "AC",
  },
  {
    name: "Alagoas",
    sigla: "AL",
  },
  {
    name: "Amapá",
    sigla: "AP",
  },
  {
    name: "Amazonas",
    sigla: "AM",
  },
  {
    name: "Bahia",
    sigla: "BA",
  },
  {
    name: "Ceará",
    sigla: "CE",
  },
  {
    name: "Distrito Federal",
    sigla: "DF",
  },
  {
    name: "Espírito Santo",
    sigla: "ES",
  },
  {
    name: "Goiás",
    sigla: "GO",
  },
  {
    name: "Maranhão",
    sigla: "MA",
  },
  {
    name: "Mato Grosso do Sul",
    sigla: "MS",
  },
  {
    name: "Mato Grosso",
    sigla: "MT",
  },
  {
    name: "Minas Gerais",
    sigla: "MG",
  },
  {
    name: "Pará",
    sigla: "PA",
  },
  {
    name: "Paraíba",
    sigla: "PB",
  },
  {
    name: "Paraná",
    sigla: "PR",
  },
  {
    name: "Pernambuco",
    sigla: "PE",
  },
  {
    name: "Piauí",
    sigla: "PI",
  },
  {
    name: "Rio de Janeiro",
    sigla: "RJ",
  },
  {
    name: "Rio Grande do Norte",
    sigla: "RN",
  },
  {
    name: "Rio Grande do Sul",
    sigla: "RS",
  },
  {
    name: "Rondônia",
    sigla: "RO",
  },
  {
    name: "Roraima",
    sigla: "RR",
  },
  {
    name: "Santa Catarina",
    sigla: "SC",
  },
  {
    name: "São Paulo",
    sigla: "SP",
  },
  {
    name: "Sergipe",
    sigla: "SE",
  },
  {
    name: "Tocantins",
    sigla: "TO",
  },
]

module.exports = {
  AccommodationMap,
  AdherenceMap,
  CopaymentMap,
  StatesMap,
}